  @import "~react-datepicker/dist/react-datepicker.css";
  @import "~leaflet/dist/leaflet.css";
  body {
    font-size: 13px;
    padding-top: 1rem;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: grayscale;
    font-family: sans-serif;
  }
  
  .ui.borderless.menu {
    background-color: transparent;
    box-shadow: none;
    flex-wrap: wrap;
    border: none;
    padding-left: 0;
    padding-right: 0;
  }
  
  .ui.borderless.menu .header.item {
    font-size: 18px;
    font-weight: 400;
  }
  
  .ui.mobile.only.grid .ui.menu .ui.vertical.menu {
    display: none;
  }
  
  .ui.mobile.only.grid .ui.vertical.menu .dropdown.icon {
    float: unset;
  }
  
  .ui.mobile.only.grid .ui.vertical.menu .dropdown.icon:before {
    content: "\f0d7";
  }
  
  .ui.mobile.only.grid .ui.vertical.menu .ui.dropdown.item .menu {
    position: static;
    width: 100%;
    background-color: unset;
    border: none;
    box-shadow: none;
  }
  
  .ui.mobile.only.grid .ui.vertical.menu .ui.dropdown.item .menu {
    margin-top: 6px;
  }
  
  .ui.container > .ui.message {
    background-color: rga(238, 238, 238);
    box-shadow: none;
    padding: 5rem 4rem;
    margin-top: 1rem;
  }
  
  .ui.message h1.ui.header {
    font-size: 4.5rem;
  }
  
  .ui.message p.lead {
    font-size: 1.3rem;
    color: #333333;
    line-height: 1.4;
    font-weight: 300;
  }

  .clickable {
    cursor: pointer
  }

  .isCollapsed{
    display: none;
  }

  .ui.attached.header.nectaPrimaryBg{
    /* background: #91c5ee; */
    background: #3A95A4;
    color: white;
  }

  .ui.grid>.row>[class*="middle aligned"].column, .ui.grid>[class*="middle aligned"].column:not(.row), .ui.grid>[class*="middle aligned"].row>.column, .ui[class*="middle aligned"].grid>.column:not(.row), .ui[class*="middle aligned"].grid>.row>.column.nectaPrimaryBg {
    display: inherit;
  }

  .ui.table thead th.nectaPrimaryBg{
    /* background: #91c5ee; */
    background: #3A95A4;
    color: white;
  }

  .ui.attached.header.nectaDarkGreyBg{
    /* background: #91c5ee; */
    background: #969696;
  }

  .ui.icon.button.clickable.hover {
    box-shadow: 3px 2px 4px 2px black;
  }

  .ui.icon.button.icon.primary{
    background: #3A95A4;
  }

  .ui.icon.button.primary{
    background: #3A95A4;
  }

  .ui.icon.button.icon.warning{
    /* background: #CD4F9D; */
    background: #dab825;
    color: #fafbfb;
  }
  
  .ui.modal>.header{
    color: #3A95A4;
  }

  .ui.form .field>label{
    color: #3A95A4;
  }

  .ui.toggle.checkbox input:checked~.box:before, .ui.toggle.checkbox input:checked~label:before {
    background-color: #3A95A4 !important;
  }

  .ui.toggle.checkbox input:checked~.box, .ui.toggle.checkbox input:checked~label {
    color: #969696!important;
  }

  .ui.toggle.checkbox input:checked~.box, .ui.toggle.checkbox label {
    color: #969696!important;
  }
  
  .ui.form input:not([type]), .ui.form input[type=date], .ui.form input[type=datetime-local], .ui.form input[type=email], .ui.form input[type=file], .ui.form input[type=number], .ui.form input[type=password], .ui.form input[type=search], .ui.form input[type=tel], .ui.form input[type=text], .ui.form input[type=time], .ui.form input[type=url]{
    color: #969696!important;
    /* border-top-style: none;
    border-left-style: none;
    border-right-style: none; */
  }

  .ui.form input.invalid-input-field, .ui.form input.invalid-input-field:focus, .ui.form .ui.selection.dropdown.invalid-input-field, .ui.form textarea[class=invalid-input-field] {
    border: 1px solid rgb(255, 168, 168);
  }

  .ui.selection.dropdown{
    color: #969696!important;
    /* border-top-style: none;
    border-left-style: none;
    border-right-style: none; */
  }

  .full-width-field {
    width: 100%;
  }

  .ui.selection.dropdown.transparent{
    color: #969696!important;
    background: transparent;
    /* border-top-style: none;
    border-left-style: none;
    border-right-style: none; */
  }

  .ui.dropdown.selected.transparent{
    color: #969696!important;
    background: transparent;
    /* border-top-style: none;
    border-left-style: none;
    border-right-style: none; */
  }

.ui.dropdown .menu .selected.item, .ui.dropdown.selected {
  background: #EBEBE7!important;
}

  .ui.menu .ui.dropdown .menu>.item {
    background: #EBEBE7!important;
  }

  .ui.button.primary{
    background: #3A95A4;
  }

  .ui.button.primary:hover{
    background: #3A95A4;
  }

  .ui.button.warning{
    /* background: #CD4F9D; */
    background: #dab825;
    color: #fafbfb;
  }

  .ui.button.success{
    /* background: #64B400; */
    background: #00b44b;
    color: #fafbfb;
  }

  .ui.button.danger{
    background: #bd2c3f;
    color: #fafbfb;
  }

  .custom-label {
    background: #3A95A4;
    color: white;
    padding: 5px;
    display: block;
    text-align: center;
    width: 100%; 
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  

  .nectaPrimaryBg{
    background: #3A95A4;
  }

  .nectaDarkGreyBg{
    background: #969696;
  }

  .nectaPrimary{
    color: #3A95A4;
  }

  h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child {
    color: #3A95A4;
  }

  .nectaWarningBg{
    background: #CD4F9D;
  }

  .nectaWarning{
    color: #CD4F9D;
  }

  .card.customSelectableCard:hover{
    cursor: pointer;
    z-index: 5;
    background: #fff;
    border: none;
    -webkit-box-shadow: 0 1px 3px 0 #bcbdbd, 0 0 0 1px #d4d4d5;
    box-shadow: 0 1px 3px 0 #bcbdbd, 0 0 0 1px #d4d4d5;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }

  amplify-s3-image.fitToParent {
    --width: 100%;
  }

  .card.centeredIconCard{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .visible.transition.fuseAlignedModal {
    display: inline-block !important;
    vertical-align: middle;
    position: relative;
  }

  .ui.attached.header.panelHeader{
    background: #3A95A4;
    color: white;
  }

  .ui.header>.icon {
    color: #3A95A4;
  }
  
  .ui.header>.icon+.content {
    color: #3A95A4;
  }

  .ui.header .icon:only-child {
    color: white;
  }

  .ui.labeled.icon.button, .ui.labeled.icon.buttons .button {
    background: #3A95A4;
    color:white;
  }

  .ui.secondary.vertical.menu>.item {
    border-bottom: 2px solid white;
  }

  .ui.label.badge {
    background-color: #3A95A4 !important;
    color: white !important;
  }

  div.item.spaced {
    padding: 10px 20px !important;
    border: 0.25px solid #d7d7d7 !important;
    background-color: #f9f9f9 !important;
    /* margin-bottom: 3px; */
    /* box-shadow: 1px 1px 3px 1px #e0e0e0 !important; */
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-Color: #888888;
  }

  .templateImg:hover .overlay {
    opacity: 0.8;
  } 

  body{
    background: #EBEBE7
  }

  /* amplify-authenticator {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100vh;;
  } */

  amplify-sign-in {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100vh;;
    background-color: #EBEBE7;
  }

  amplify-greetings {
    --background-color: #EBEBE7;
    --border-color: #EBEBE7;
  }

  :root {
    --amplify-primary-color: #3A95A4;
    --amplify-primary-tint: #3A95A4;
    --amplify-primary-shade: #3A95A4;
    --amplify-secondary-color: #3A95A4;
    --amplify-background-color: #FFFFFF;
  }

  .nectaPaginationBackground {
    width: 100%;
    height: 50px;
    padding: 5px;
    background-color: #FFF;
    box-Shadow: 1px 2px 4px 1px #a9a9a9;
  }

  .nectaPagination {
    display: inline-flex;
    float: right;
    margin-right: 20px;
    justify-content: space-around;
    list-style: none;
    cursor: pointer;
  }

  .nectaPagination a {
    padding: 7px;
    margin-left: 5px;
    border-radius: 5px;
    border: 1px solid #3A95A4;
    background-color: #FFF;
    color: #3A95A4;
  }

  .nectaPagination a:hover {
    padding: 7px;
    margin-left: 5px;
    border-radius: 5px;
    border: 1px solid #3A95A4;
    background-color: #3A95A4;
    color: #FFF;
  }

  .nectaPagination a:active {
    padding: 7px;
    margin-left: 5px;
    border-radius: 5px;
    border: 1px solid #3A95A4;
    background-color: #3A95A4;
    color: #FFF;
  }

  .nectaPagination_link {
    font-weight: bold;
  }

  .nectaPagination_link_active {
    font-weight: bold;
    color: white;
    background-color: #3A95A4;
  }

  .validation-error {
    color: rgb(248, 83, 83);
    font-size: 11px;
    font-weight: bold;
  }

  .question-item {
    transition: all .1s ease-in-out;
  }

  .question-item:hover {
    background-color:#f5fbfc;
    border-radius: 5px;
  }

  .edit-button {
    transition: all .1s ease-in-out;
  }

  .visible-input {
    background-color: #f8f8f8;
    padding: 5px 10px;
    border: 1px solid #ddd;
    color: #333;
    font-weight: bold;
  }

  .table-cell {
    color: #3A95A4;
  }

  .table-header-cell {
    background-color: #3A95A4 !important;
    color: white !important;
    font-size: large;
    font-weight: bold;
  }

  .chat-bubble {
    color: white;
    padding: 8px;
    border-radius: 8px;
    margin-bottom: 8px;
    max-width: 70%;
    word-wrap: break-word;
    clear: both;
    position: relative;
}

.user-bubble {
    background-color: #25D366;
    float: right;
}

.bot-bubble {
    background-color: #34B7F1;
    float: left;
}

.user-bubble::after{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
}

.user-bubble::after {
    border-width: 0 8px 8px 0;
    border-color: transparent transparent #25D366 transparent;
    right: -8px;
    top: 70%;
    transform: translateY(-50%);
}

.bot-bubble::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
}

.bot-bubble::after {
    border-width: 0 0 8px 8px;
    border-color: transparent transparent #34B7F1 transparent;
    left: -8px; 
    top: 70%;
    transform: translateY(-50%);
}
  
.leaflet-container{
  height: 50vh;
}

.responsive-table.ui.table {
  table-layout: fixed;
  word-wrap: break-word;
}

.responsive-table.ui.table th,
.responsive-table.ui.table td {
  overflow: hidden;
  text-overflow: ellipsis;
}

.pre-wrapper {
  white-space: pre-wrap;
  word-break: break-word;
  max-height: 200px;
  overflow-y: auto;
}

.responsive-container {
  padding: 1rem;
  max-width: 100% !important;
}

.menu-subitem {
  color: #3A95A4 !important;
} 

.menu-subitem:hover {
  font-weight: bold !important;
}

.menu-item {
  font-weight: bold !important;
  color: #3A95A4 !important;
  font-size: 15px !important; 
} 

.menu-item:hover{
  background-color: white !important;
}
